$PRODUCT_ITEM_SIZE: 12rem;
$PRODUCT_ITEM_PADDING: 1.25rem;
$PRODUCT_ITEM_IMAGE_SIZE: calc(
  #{$PRODUCT_ITEM_SIZE} - (#{$PRODUCT_ITEM_PADDING} * 2)
);

$PRODUCT_ITEM_SIZE_SMALL: 8rem;
$PRODUCT_ITEM_PADDING_SMALL: 1rem;
$PRODUCT_ITEM_IMAGE_SIZE_SMALL: calc(
  #{$PRODUCT_ITEM_SIZE_SMALL} - (#{$PRODUCT_ITEM_PADDING_SMALL} * 2)
);

.purchase-container {
  width: 100%;
  display: grid;
  gap: 2rem;
  grid-template-columns: 3fr 2fr;
  max-width: 1440px;
  margin: 0 auto;
  padding-bottom: 5rem;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
  }

  .purchase-list {
    display: flex;
    flex-direction: column;

    .products-lsit {
      margin-top: 2rem;
      width: 100%;
      display: grid;
      grid-auto-rows: minmax($PRODUCT_ITEM_SIZE, min-content);
      gap: 2rem;

      .product-item {
        transition: all 150ms ease-in-out;
        box-shadow: 2px 2px 10px 3px rgba(0, 0, 0, 0.05);
        background-color: white;
        overflow: hidden;
        border-radius: 10px;
        padding: $PRODUCT_ITEM_PADDING;
        display: flex;
        gap: 1rem;
        border: 1px solid transparent;

        .product-image {
          min-height: $PRODUCT_ITEM_IMAGE_SIZE;
          min-width: $PRODUCT_ITEM_IMAGE_SIZE;
          overflow: hidden;
          border-radius: 4px;
          position: relative;

          img {
            height: auto;
            width: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: all 300ms ease-in-out;
          }

          &:hover > img {
            transform: translate(-50%, -50%) scale(1.1);
          }
        }

        @media screen and (max-width: 570px) {
          flex-flow: column wrap;

          .product-image {
            width: $PRODUCT_ITEM_IMAGE_SIZE;
            margin: 0 auto;
          }
        }

        .product-info {
          flex: 1 1 auto;
          display: flex;
          flex-direction: column;
          gap: 1rem;

          .product-info-header {
            display: flex;
            gap: 1rem;
            flex-flow: row nowrap;
            align-items: center;

            .price {
              opacity: 0.4;
              flex: 1 1 auto;
              text-align: right;
              font-weight: bold;
            }

            .gift-tag {
              color: #d4bca8;
              font-size: 0.825rem;
              display: flex;
              align-items: center;
            }
          }

          p {
            display: flex;
            flex-flow: row wrap;
            gap: 1rem;
            word-wrap: break-word;

            .product-description-controll {
              cursor: pointer;
              transition: color 150ms ease-in-out;
              color: black;
              &:hover {
                color: blue;
              }
            }
          }

          .product-info-controll {
            display: flex;
            gap: 1rem;
            flex-flow: row nowrap;
            justify-content: space-between;

            &.gift {
              flex-flow: column wrap;
            }

            > * {
              min-height: 2rem;
            }

            .input {
              margin: 0;
            }

            select {
              background-color: #fcf7f3;
              border-radius: 0.5rem;
              font-size: 1rem;
              padding: 0.5rem;
            }

            @media screen and (max-width: 570px) {
              &:not(.mini) {
                flex-flow: column wrap;
              }
            }

            .btn:disabled {
              background-color: #eee;
              cursor: not-allowed;
              border-radius: 0.5rem !important;
              box-shadow: none !important;
            }

            &.mini {
              justify-content: flex-end;

              .ctrl {
                cursor: pointer;

                .icon {
                  transition: color 150ms ease-in-out;
                }

                &.default {
                  .icon {
                    color: #72716b;
                  }

                  &:hover > .icon {
                    color: darken(#72716b, 20);
                  }
                }

                &.danger {
                  .icon {
                    color: lighten(#78201b, 20);
                  }

                  &:hover > .icon {
                    color: #78201b;
                  }
                }
              }
            }
          }

          p {
            opacity: 0.7;
          }

          .product-info-options {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            opacity: 0.7;
          }
        }

        &:hover {
          box-shadow: 2px 2px 7px 3px rgba(0, 0, 0, 0.07);
          border-color: #999;
        }
      }

      &.small {
        grid-auto-rows: minmax($PRODUCT_ITEM_SIZE_SMALL, min-content);
        .product-item {
          padding: $PRODUCT_ITEM_PADDING_SMALL;
          .product-image {
            min-height: $PRODUCT_ITEM_IMAGE_SIZE_SMALL;
            min-width: $PRODUCT_ITEM_IMAGE_SIZE_SMALL;
          }
        }
      }
    }
  }
}

.cart-info {
  padding-top: 2rem;
  margin-top: auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .btn {
    width: 100%;
    margin-top: 1rem;
  }

  .cart-price {
    p {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 0.25rem;
      color: #666;

      &:last-of-type {
        color: #333;
        margin-top: 0.5rem;
      }

      span:last-child {
        font-weight: 600;
      }
    }
  }

  .cart-discount {
    display: flex;
    flex-direction: column;

    .cart-discount-input {
      display: flex;
      gap: 1rem;

      .input {
        margin-bottom: 0;

        &:disabled {
          cursor: not-allowed;
          color: #999;
        }
      }

      button {
        max-width: 100px;
      }
    }

    .cart-discount-label {
      font-weight: 500;
    }

    .cart-discount-error {
      color: rgb(185, 10, 10);
      font-size: small;
    }
  }
}
