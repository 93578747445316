/* fonts */
@font-face {
  font-family: 'Groteskly';
  font-style: normal;
  font-weight: normal;
  src: local('Groteskly'), url(./assets/GrotesklyYoursNormal.otf);
}

/* base */
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: inherit;
}

body,
html {
  box-sizing: border-box;
  font-family: 'Groteskly', sans-serif;
  font-size: 90%;
  line-height: 24px;
  width: 100%;
  color: rgb(22, 13, 54);
}

::selection {
  background-color: #ebc57a;
}

a {
  text-decoration: none;
  color: rgb(22, 13, 54);
  cursor: pointer;
}

h1 {
  font-size: 2.5rem;
  font-family: 'Groteskly', sans-serif;
  font-weight: 500;
}

h2 {
  font-family: 'Groteskly', sans-serif;
  font-size: 1.8rem;
  font-weight: 400;
}

h3 {
  font-family: 'Groteskly', sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  letter-spacing: 0.5px;
}

h4,
button {
  font-family: 'Groteskly', sans-serif;
  font-size: 0.8rem;
  letter-spacing: 1px;
  font-weight: 400;
}

h5,
label {
  font-size: 0.8rem;
  letter-spacing: 0.5px;
  font-weight: 600;
}

h6 {
  font-size: 0.7rem;
}

code {
  font-family: monospace;
  font-size: 1rem;
  background-color: #f3e8dc;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

input {
  border: 1px solid RGBA(128, 128, 128, 0.5);
  font-family: 'Groteskly', sans-serif;
  font-size: 1rem;
  letter-spacing: 0.5px;
}

input:focus {
  outline-color: black;
}

button:focus {
  outline: none;
}

.flex {
  display: flex;
}

.column {
  flex-direction: column;
}

.wrap {
  flex-wrap: wrap;
}

.justifyCenter {
  justify-content: center;
}

.justifyStart {
  justify-content: flex-start;
}

.justifyEnd {
  justify-content: flex-end;
}

.justifySpaceEvenly {
  justify-content: space-evenly;
}

.justifySpaceAround {
  justify-content: space-around;
}

.justifySpaceBetween {
  justify-content: space-between;
}

.alignCenter {
  align-items: center;
}

.alignStart {
  align-items: flex-start;
}

.alignEnd {
  align-items: flex-end;
}

.alignSpaceEvenly {
  align-items: space-evenly;
}

.alignSpaceAround {
  align-items: space-around;
}

.alignSpaceBetween {
  align-items: space-between;
}

.alignBaseline {
  align-items: baseline;
}

.splitEvenly {
  flex: 0 0 50%;
}

.fullWidth {
  width: 100%;
}

.fullHeight {
  height: 100vh;
}

.almostFullWidth {
  width: 90%;
}

.halfWidth {
  width: 50%;
}

.quarterWidth {
  width: 25%;
}

.block {
  display: block;
}

.inlineBlock {
  display: inline-block;
}

.paddingOne {
  padding: 1rem;
}

.paddingOneHalf {
  padding: 1.5rem;
}

.paddingThree {
  padding: 3rem;
}

.padBottomOne {
  padding-bottom: 1rem;
}

.padBottomThree {
  padding-bottom: 3rem;
}

.padChildrenOne > * {
  padding-bottom: 1rem;
}

.padChildrenThree > * {
  padding-bottom: 3rem;
}

.marginChildrenOne > * {
  margin-bottom: 1rem;
}

.spaceThree {
  width: 3rem;
  height: 3rem;
  display: inline-block;
}

.spaceOne {
  width: 1rem;
  height: 1rem;
  display: inline-block;
}

.border {
  border: 1px solid RGBA(128, 128, 128, 0.5);
}

.noBorder {
  border: 1px solid transparent;
}

.pointer {
  cursor: pointer;
}

.page {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.page__body {
  text-align: center;
}

header {
  align-self: center;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1.5rem;
}

.inlineForm {
  width: 35%;
  text-align: center;
}
.inlineForm__notif {
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inlineForm__submit {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input:focus {
  border: 1px solid RGBA(128, 128, 128, 0.5);
}
.input--fail {
  border: 1px solid #f39c8199;
}
.input--fail:focus {
  border: 1px solid #f39c81d9;
}

.input__pass {
  position: relative;
  color: #44433e;
}

.showPasswordButton {
  position: absolute;
  display: inline;
  left: 90%;
  bottom: 35%;
  cursor: pointer;
}

.btn {
  min-height: 3rem;
  min-width: 3rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.35s;
}
.btn:hover {
  box-shadow: 2px 2px 8px #dcd8d1;
  border-radius: 0.5rem;
}
.btn:active {
  background-color: #fffaf0;
}
.btn--active {
  background-color: #f3e8dc;
}
.btn--active .btn:hover {
  box-shadow: none;
}
.btn--CTA {
  min-width: 6rem;
  background-color: #f3e8dc;
}

.btnGroup {
  display: flex;
}
.btnGroup > * {
  margin-right: 1rem;
}

.icon {
  font-size: 1.2rem;
  color: #c3c2bd;
}

.notifError {
  display: block;
  background-color: #f39c81d9;
  color: #fffdfa;
  border-radius: 0.5rem;
  border: 1px solid #78201b;
  padding: 1rem;
}

.loading {
  display: flex;
  align-items: center;
  width: 10rem;
  justify-content: center;
}

.pulse {
  display: inline-block;
  position: relative;
  height: 3rem;
  width: 3rem;
  background-color: #72716b;
  border-radius: 50%;
  margin: 0 0.5rem;
  animation: pulse 3s infinite;
  animation-delay: 1s;
}

.cmsColumnLayout {
  display: flex;
}

.cmsColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.contentfulPage {
  text-align: center;
}

.logo {
  max-width: 100px;
  max-height: 100px;
  padding: 5px;
}

.pointer-events-none {
  pointer-events: none;
}
